
import { defineComponent, ref, onMounted, watch, computed } from "vue";
import { hideModal } from "@/core/helpers/dom";
import {
  ApiBase,
  ApiShipments,
  ApiLogisticsServiceProvider,
  ApiFulfillmentOrders,
} from "@/core/api";
import addPreStockOutList from "@/views/transport-management/shipments/AddStockOut.vue";
import _ from "lodash";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import {
  modalKeyDownListener,
  modalShowListener,
} from "@/core/directive/function/common";

interface TaggingItem {
  label: string;
  value: string;
}

export default defineComponent({
  name: "add-shipment",
  components: {
    addPreStockOutList,
  },
  props: {
    fulfillment_order_id: {
      type: Number,
      required: false,
      default: 0,
    },
    fulfillment_order_number: {
      type: String,
      required: false,
      default: "",
    },
    fulfillment_order_type: {
      type: Number,
      required: false,
      default: 0,
    },
    custom_declaration_by: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const addShipments = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);
    const showStockOut = ref<boolean>(false);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      shipment_type: "",
      service_provider: "",
      tracking_no: "",
      destination_warehouse_id: "",
      country: "",
      pick_up_by: "",
    });

    const options = ref({
      shipment_type: [] as TaggingItem[],
      service_provider: [] as TaggingItem[],
      warehouse: [] as TaggingItem[],
      warehouseMap: new Map([]),
    });

    const rules = ref({
      shipment_type: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      service_provider: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      tracking_no: [
        {
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (value === "" && formData.value.shipment_type == "1") {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      // pick_up_by: [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      destination_warehouse_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    watch(
      () => formData.value.tracking_no,
      (newValue, oldValue) => {
        formData.value.tracking_no = newValue.replace(
          /[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5]/g,
          ""
        );
      }
    );

    const warehouseChanage = (e) => {
      if (e) {
        formData.value.country = options.value.warehouseMap.get(e) as string;
      } else {
        formData.value.country = "";
      }
    };

    const serviceProviderCHange = (e) => {
      if (e) {
        if (e == -1) {
          formData.value.tracking_no = "";
        } else {
          formData.value.pick_up_by = "";
        }
      } else {
        formData.value.tracking_no = "";
        formData.value.pick_up_by = "";
      }
    };

    const getTaggingData = () => {
      ApiBase.getTaggingData({
        short_key: ["shipment_type"],
      })
        .then(({ data }) => {
          if (data.code == 0) {
            options.value.shipment_type = data.data.shipment_type.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getWarehouseData = async () => {
      const { data } = await ApiFulfillmentOrders.getWarehouseData({
        // mode: 1
      });
      if (data.code == 0) {
        options.value.warehouse = data.data;
        data.data.map((item) => {
          options.value.warehouseMap.set(item.value, item.country);
        });
      }
    };

    const getLogisticsServiceProviderData = async () => {
      const { data } =
        await ApiLogisticsServiceProvider.getLogisticsServiceProviderData({
          max_item: "all",
        });
      if (data.code == 0) {
        let shipmentProviderOptionsData = data.data;
        let pickUp = {
          label: t("shipments.pickUpBy"),
          value: -1,
        };
        shipmentProviderOptionsData.push(pickUp);
        if (props.fulfillment_order_id == 0) {
          options.value.service_provider = data.data;
        } else {
          options.value.service_provider = shipmentProviderOptionsData;
        }
      }
    };

    const getFromInfo = () => {
      loading.value = true;
      Promise.all([
        getTaggingData(),
        getWarehouseData(),
        getLogisticsServiceProviderData(),
      ])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!loading.value) {
        if (!formRef.value) {
          return;
        }

        formRef.value.validate((valid) => {
          if (valid) {
            if (loading.value) return;
            loading.value = true;
            if (submitButton.value) {
              submitButton.value.setAttribute("data-kt-indicator", "on");
            }
            if (
              formData.value.shipment_type != "2" &&
              formData.value.shipment_type != "3"
            ) {
              ApiShipments.addShipment(formData.value)
                .then(({ data }) => {
                  loading.value = false;
                  submitButton.value?.removeAttribute("data-kt-indicator");
                  if (data.code == 0) {
                    showFormSubmitSuccessMsg(() => {
                      resetForm();
                      hideModal(addShipments.value);
                      emit("update-list");
                    });
                  } else {
                    showServerErrorMsg(data);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              return false;
            }
          } else {
            showValidateErrorMsg();
            return false;
          }
        });
      }
    };

    const isDisabledSubmit = computed(() => {
      if (
        formData.value.service_provider === "" ||
        formData.value.destination_warehouse_id === ""
      ) {
        return true;
      }
      return false;
    });

    const handleDiscard = () => {
      resetForm();
      hideModal(addShipments.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    const updateList = () => {
      if (props.fulfillment_order_id != 0) {
        resetForm();
        hideModal(addShipments.value);
      }
      emit("update-list");
    };

    const resetShow = () => {
      showStockOut.value = false;
    };

    onMounted(() => {
      modalShowListener(addShipments.value, () => {
        modalKeyDownListener(addShipments.value, (e) => {
          if (e.key == "Enter" && !isDisabledSubmit.value) {
            showStockOut.value = true;
          }
        });
      });
      getFromInfo();
    });

    return {
      t,
      props,
      loading,
      formData,
      rules,
      formRef,
      addShipments,
      submitButton,
      options,
      isDisabledSubmit,
      showStockOut,
      warehouseChanage,
      serviceProviderCHange,
      submit,
      handleDiscard,
      resetForm,
      updateList,
      resetShow,
      getTaggingData,
    };
  },
});
